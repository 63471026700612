import { memo } from 'react';

export type SchemaType = {
  article?: Article;
  breadcrumb?: Breadcrumb;
  product?: Product;
  podcast?: Podcast;
  website?: Website;
  organization?: Organization;
  video?: Video;
};

export interface InternalSchemaI {
  '@context'?: string;
  '@type': string;
}

export interface Article extends InternalSchemaI {
  '@type': 'Article' | 'NewsArticle';
  headline: string;
  alternativeHeadline: string;
  image: string;
  author: Author;
  publisher?: Publisher;
  url: string;
  mainEntityOfPage: MainEntityOfPage;
  datePublished?: string;
  dateModified?: string;
}

export interface Breadcrumb extends InternalSchemaI {
  '@type': 'BreadcrumbList';
  itemListElement: {
    '@type': string;
    position: number;
    name: string;
    item: string;
  }[];
}

export interface Author {
  '@type': string;
  name: string;
  url?: string;
}

export interface Publisher {
  '@type': string;
  name: string;
  logo: Logo;
}

export interface Logo {
  '@type': string;
  url: string;
}

export interface MainEntityOfPage {
  '@type': string;
  '@id': string;
}

export interface AggregateRating {
  '@type': string;
  ratingValue: number;
  reviewCount: number;
  bestRating: number;
  worstRating: number;
}

export interface Offer {
  '@type': string;
  availability: string;
  price: number;
  priceCurrency: string;
  url: string;
}

export interface Review {
  '@type': string;
  author: Author;
  reviewBody: string;
  name: string;
  reviewRating: ReviewRating;
}

export interface ReviewRating {
  '@type': 'Rating';
  bestRating: number;
  ratingValue: number;
  worstRating: number;
}

export interface Product extends InternalSchemaI {
  '@type': 'Product';
  aggregateRating: AggregateRating;
  name: string;
  image: string;
  offers: Offer[];
  review: Review;
  description: string;
  brand: string;
  sku: number;
}

export interface AssociatedMedia {
  '@type': string;
  contentUrl: string;
}

export interface PartOfSeries {
  '@type': string;
  name: string;
  url: string;
}

export interface ProductionCompany {
  '@type': string;
  name: string;
}

export interface Podcast extends InternalSchemaI {
  '@type': 'PodcastEpisode';
  url: string;
  name: string;
  datePublished: string;
  timeRequired: string;
  description: string;
  associatedMedia: AssociatedMedia;
  partOfSeries: PartOfSeries;
  productionCompany: ProductionCompany;
}

export interface Website extends InternalSchemaI {
  '@type': 'WebSite';
  name: string;
  url: string;
  description: string;
  alternateName: string;
  inLanguage: string;
}

export interface Organization extends InternalSchemaI {
  '@type': 'Organization';
  url: string;
  logo: {
    '@type': string;
    url: string;
    width: string;
    height: string;
  };
  name: string;
  sameAs: string[];
  alternateName: string;
}

export interface Video extends InternalSchemaI {
  '@type': 'VideoObject';
  name: string;
  description: string;
  thumbnailUrl: string;
  uploadDate: string;
  duration: string;
  embedUrl: string;
  dataPublished: string;
}

type InternalSchemaProps = {
  schema: Article | Breadcrumb | Product | Podcast | Video | Website | Organization;
};

export function InternalSchema({ schema }: InternalSchemaProps) {
  const schemaUse = schema;

  if (!schemaUse['@context']) {
    schemaUse['@context'] = 'http://schema.org';
  }

  if (schemaUse['@type'] === 'Article') {
    if (schemaUse?.datePublished) {
      schemaUse['@type'] = 'NewsArticle';
    }
  }

  if ('author' in schemaUse && !schemaUse.publisher) {
    schemaUse.publisher = {
      '@type': 'Organization',
      name: 'Canaltech',
      logo: {
        '@type': 'ImageObject',
        url: 'https://img.canaltech.com.br/logo-ct.png',
      },
    };
  }

  // eslint-disable-next-line react/no-danger
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaUse) }} />;
}

export const ApplySchema = ({ schema }: { schema: SchemaType }) => (
  <>
    {Object.values(schema).map((schemaItem, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <InternalSchemaMemo key={index} schema={schemaItem} />
    ))}
  </>
);

const InternalSchemaMemo = memo(InternalSchema, () => true);

export default InternalSchemaMemo;
