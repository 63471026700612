import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import Top from '@components/Layout/Top';
import { PageWrapper } from '@components/Layout/PageWrapperMenu/styles';
import GlobalContainer from '@/components/Layout/GlobalContainer';
import { LayoutTypes } from '@/shared/types/home.types';
import HeadersPage from '@/components/Layout/Headers';
import useBackground from '@/hooks/useBackground';
import { useAmp } from 'next/amp';
import usePageViewComscoreRequest from '@/hooks/usePageViewComscoreRequest';
import usePageViewComscoreScript from '@/hooks/usePageViewComscoreScript';
import useAmpComponent from '@/hooks/useAmpComponent';
import Banner from './Banner';
import { ApplySchema } from '../Internals/InternalSchema';
import { CazambaAds } from '../Ads/CazambaAds';
import AdsGeneratorMemo from '../Ads/Google/AdsTypeGeneratorsMemo';

interface LayoutProps {
  children: ReactNode | ReactNode[];
  layoutData: LayoutTypes;
  showBackgroundAd?: boolean;
  headingTag?: 'h1' | 'h2';
  isAdvertorial?: boolean;
  ampPixel?: boolean;
  ampPixelData?: {
    cu: number;
    cauldronUrl: string;
  };
}

export default function Layout({
  children,
  layoutData,
  showBackgroundAd = true,
  headingTag = 'h2',
  isAdvertorial = false,
  ampPixel = false,
  ampPixelData = null,
}: LayoutProps) {
  const AdsEvents = dynamic(() => import('@/components/Ads/Google/AdsEvents'), { ssr: false });
  const BackgroundGoogleAds = dynamic(() => import('@/components/Ads/Google/BackgroundGoogleAds'), { ssr: false });
  const OneAOneAdGeneratorMemo = dynamic(() => import('@/components/Ads/Google/OneAOne'), { ssr: false });
  const InterstitialsAds = dynamic(() => import('@/components/Ads/Google/Interstitial'), { ssr: false });
  const { Navbar } = useAmpComponent();
  const { isAdActive } = useBackground();

  const { segmentacoes, tagsData, navBarData, schema } = layoutData;

  const isAmp = useAmp();

  usePageViewComscoreRequest();
  usePageViewComscoreScript();

  return (
    <GlobalContainer>
      {schema && <ApplySchema schema={schema} />}
      {!isAmp && <OneAOneAdGeneratorMemo />}
      {!isAmp && !isAdvertorial && <CazambaAds />}
      <AdsEvents segmentacoes={segmentacoes} />
      <HeadersPage headPage={tagsData} />
      <Navbar navBarContent={navBarData} headingTag={headingTag} />
      <Banner />
      {!isAmp && <Top Ads={showBackgroundAd ? isAdActive : false} shouldHaveAds={!isAdvertorial} />}
      {!isAdvertorial && <BackgroundGoogleAds />}
      <PageWrapper Ads={showBackgroundAd ? isAdActive : false} isAmp={isAmp}>
        {children}
        <InterstitialsAds />
      </PageWrapper>
      {!isAdvertorial && !isAmp && <AdsGeneratorMemo type="anchor" />}
      {!isAdvertorial && !isAmp && <AdsGeneratorMemo type="Anchor_Footer" />}
      {isAmp && ampPixel && ampPixelData && <amp-pixel src={`${ampPixelData?.cauldronUrl}?id=${ampPixelData?.cu}&image=1`} layout="nodisplay" />}
    </GlobalContainer>
  );
}
