import { useEffect } from 'react';
import { useAmp } from 'next/amp';

export default function usePageviewConscoreScript() {
  const isAmp = useAmp();

  useEffect(() => {
    if (!isAmp) {
      const self: any = window;

      if (self.COMSCORE) {
        self.COMSCORE.beacon({
          c1: '2',
          c2: '20572948',
          options: {
            enableFirstPartyCookie: true,
            bypassUserConsentRequirementFor1PCookie: true,
          },
        });
      }
    }
  }, []);
}
